
<template>
  <div class="is-boxed has-animations">
    <div class="body-wrap">
      <header class="site-header">
        <div class="container">
          <div class="site-header-inner">
            <div class="brand header-brand">
                <span class="fs-5 ms-1 text-muted">Beta</span>
              <h1 class="m-0">
                <a :href="SITE_URL">
                  <img
                    class="header-logo-image"
                    :src="require('@/assets/images/logo.png')"
                    alt="Logo"
                    width="230"
                  />
                </a>
              </h1>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section class="hero">
          <div class="container">
            <div class="hero-inner">
              <div class="hero-copy w-100">
                <div class="mt-1">
                  <h1 class="hero-title2">{{$t("about.title")}}</h1>
                  <h2 class="hero-paragraph">
                   {{$t("about.description")}} 
                  </h2>
                </div>
                <div class="mt-15">
                  <h2 class="hero-paragraph">
                   {{$t("about.description2")}}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer class="site-footer">
        <div class="container">
          <div class="site-footer-inner">
            <div class="brand footer-brand"></div>
            <ul class="footer-links list-reset">
              <li >
                <a href='/Whitepaper.pdf' target="_blank">Whitepaper</a>
              </li>
              <li class="ms-4">
                <a :href="`/${this.$i18n.locale}/about`">{{$t("homepage.aboutus")}}</a>
              </li>
              <li class="ms-4">
                <a href="#">{{$t("homepage.faqs")}}</a>
              </li>
              <li class="ms-4">
                <a :href="`/${this.$i18n.locale}/support`">{{$t("homepage.support")}}</a>
              </li>
              <li class="ms-4">
                <language-switcher class="someClass"></language-switcher>
              </li>
            </ul>
            <ul class="footer-social-links list-reset">
              <li>
                <a
                  href="https://twitter.com/altcoincase?t=50pwg2CIJPgsbYbPYEah0A&s=09"
                >
                  <span class="screen-reader-text">Twitter</span>
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                      fill="#0270D7"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/altcoincase/">
                  <span class="screen-reader-text">Linkedin</span>
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                      fill="#0270D7"
                    />
                  </svg>
                </a>
              </li>
            </ul>
            <div class="footer-copyright text-light">
              <a href="#">
                <img
                  class="header-logo-image"
                  :src="require('@/assets/images/altcoincase-icon.png')"
                  alt="Logo"
                  width="35"
                />
              </a>
              <span class="text-light ms-2 mt-2"
                >&copy; 2021 Altcoincase, {{$t("homepage.allrightsreserved")}}</span
              >
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
export default {
  components: { LanguageSwitcher },
  name: "About",
  data() {
    return {
        SITE_URL: process.env.VUE_APP_SITE_URL,
    };
  },
  methods: {

  },
  mounted() {
  },
};
</script>

<style lang="" scoped src="../assets/anasayfa/css/style.css"></style>



