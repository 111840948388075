<template>
  <div>
    <About></About>
  </div>
</template>

<script>
// @ is an alias to /src
import About from '@/components/About.vue'

export default {
  name: 'About-view',
  components: {
    About,
  }
}
</script>
